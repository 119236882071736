import React from "react";

const VisionValues = () => {
  return (
    <>
      <section className="faq-one faq-one--faq">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="faq-one__accordions">
                <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                  <div className="accrodion active  wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1000ms">
                    <div className="accrodion-title">
                      <h4 style={{textAlign:"center"}}> Our Vision</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>Become a leading innovator in sustainable agriculture. Become a leading innovator in sustainable agriculture by providing high quality and cutting edge agricultural solutions that boost production while protecting the environment. Help build a better agricultural economy by providing access to affordable solutions to farming communities.
                        </p>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="faq-one__accordions">
                <div className="accrodion-grp2" data-grp-name="faq-one-accrodion">
                  <div className="accrodion active  wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1000ms">
                    <div className="accrodion-title">
                      <h4 style={{textAlign:"center"}}> Our Mission</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>To help customers achieve their business objectives by providing innovative solutions and delivering the highest standards of customer service through our commitment to excellence, experience, originality, efficiency and integrity.
                        </p>
                        <br/>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default VisionValues;
